import React from 'react'

interface Props {
  styles: { [k: string]: string }
}

const PoliticaPrivacidadEs = ({ styles }: Props) => (
  <div className={styles.container}>
    <h1 className={styles.title}>Política de Privacidad</h1>
    <p>
      La confidencialidad y la seguridad son valores primordiales de EL PASO
      2000 y, en consecuencia, asumimos el compromiso de garantizar la
      privacidad del Usuario en todo momento y de no recabar información
      innecesaria. A continuación, le proporcionamos toda la información
      necesaria sobre nuestra Política de Privacidad en relación con los datos
      personales que recabamos, explicándole:
    </p>
    <ul className={styles.list}>
      <li>Quién es el responsable del tratamiento de sus datos.</li>
      <li>Para qué finalidades recabamos los datos que le solicitamos.</li>
      <li>Cuál es la legitimación para su tratamiento.</li>
      <li>Durante cuánto tiempo los conservamos.</li>
      <li>A qué destinatarios se comunican sus datos.</li>
      <li>Cuáles son sus derechos.</li>
    </ul>
    <h2 className={styles.section_title}>
      <strong>1.&nbsp;Responsable</strong>:
    </h2>
    <p>EL PASO 2.000 TECHNOLOGY, S.L.U. (B76177880)</p>
    <p>C/Josefina Mayor, 32.</p>
    <p>35219 Telde, Las Palmas</p>
    <p>atencionalcliente@elpaso2000.com</p>
    <h2 className={styles.section_title}>
      <strong>
        2. Finalidades, legitimación y conservación de los tratamientos de los
        datos enviados a través de:
      </strong>
    </h2>
    <ul className={styles.list}>
      <li>
        <strong>Formulario de contacto.</strong>
      </li>
    </ul>
    <p>
      <strong>Finalidad:</strong> Facilitarle un medio para que pueda ponerse en
      contacto con nosotros y contestar a sus solicitudes de información, así
      como enviarle comunicaciones de nuestros productos, servicios y
      actividades, inclusive por medios electrónicos, si marca la casilla de
      aceptación.
    </p>
    <p>
      <strong>Legitimación:</strong> El consentimiento del usuario al
      solicitarnos información a través de nuestro formulario de contactos y al
      marcar la casilla de aoceptación de envío de información.
    </p>
    <p>
      <strong>Conservación:</strong> Hasta una vez resulta su solicitud por
      medio de nuestro formulario o contestada por correo electrónico, si no ha
      generado un nuevo tratamiento. En caso de haber aceptado recibir envíos
      comerciales, hasta que solicite la baja de los mismos.
    </p>
    <ul className={styles.list}>
      <li>
        <strong>Formulario de cita previa.</strong>
      </li>
    </ul>
    <p>
      <strong>Finalidad:</strong> Facilitarle un medio para solicitar cita en
      nuestros talleres, así como enviarle comunicaciones de nuestros productos,
      servicios y actividades, inclusive por medios electrónicos, si marca la
      casilla de aceptación.
    </p>
    <p>
      <strong>Legitimación:</strong> El consentimiento del usuario al
      solicitarnos información a través de nuestro formulario de cita previa y
      al marcar la casilla de aceptación de envío de información.
    </p>
    <p>
      <strong>Conservación:</strong> Hasta la fecha de la cita si no ha generado
      un nuevo tratamiento. En caso de haber aceptado recibir envíos
      comerciales, hasta que solicite la baja de los mismos.
    </p>
    <ul className={styles.list}>
      <li>
        <strong>Formulario de registro.</strong>
      </li>
    </ul>
    <p>
      <strong>Finalidad:</strong> Darle de alta como usuario y permitirles el
      acceso y la contratación de los servicios de nuestra web e informarle de
      nuestras actividades, productos y servicios, así como enviarle
      comunicaciones comerciales de nuestros productos, servicios inclusive por
      medios electrónicos), si marca la casilla de aceptación.
    </p>
    <p>
      <strong>Legitimación:</strong> El consentimiento del usuario al
      registrarse a través de nuestro formulario de registro y al marcar la
      casilla de aceptación de envío de información.
    </p>
    <p>
      <strong>Conservación:</strong> Hasta que el usuario registrado solicite su
      baja y posteriormente durante los plazos legalmente previstos.
    </p>
    <ul className={styles.list}>
      <li>
        <strong>Envío de correos electrónicos.</strong>
      </li>
    </ul>
    <p>
      <strong>Finalidad:</strong> Contestar a sus solicitudes de información,
      atender sus peticiones y responder sus consultas o dudas.
    </p>
    <p>
      <strong>Legitimación:</strong> El consentimiento del usuario al
      solicitarnos información a través de la dirección de correo electrónico.
    </p>
    <p>
      <strong>Conservación:</strong> Una vez resulta contestada su petición por
      correo electrónico, si no ha generado un nuevo tratamiento.
    </p>
    <ul className={styles.list}>
      <li>
        <strong>Envío del currículum vitae por correo electrónico.</strong>
      </li>
    </ul>
    <p>
      <strong>Finalidad:</strong> Disponer de su currículum para participar en
      nuestros procesos de selección de personal.
    </p>
    <p>
      <strong>Legitimación:</strong> El consentimiento del usuario al remitirnos
      su información personal y currículum para nuestros procesos de selección
      de personal.
    </p>
    <p>
      <strong>Conservación:</strong> Durante el desarrollo de los procesos de
      selección de personal abiertos y durante 1 año para futuros procesos.
    </p>
    <ul className={styles.list}>
      <li>
        <strong>Suscripción a nuestra newsletter</strong>
      </li>
    </ul>
    <p>
      <strong>Finalidad:</strong> Envío de nuestro boletín comercial y de
      comunicaciones informativas y publicitarias sobre nuestros productos o
      servicios que sean de su interés, incluso por medios electrónicos.
    </p>
    <p>
      <strong>Legitimación:</strong> El consentimiento del usuario al
      suscribirse a nuestros envíos comerciales y/o newsletters.
    </p>
    <p>
      <strong>Conservación:</strong> Hasta que el interesado revoque el
      consentimiento y solicite la baja del servicio.
    </p>
    <p>
      <strong>
        Obligación de facilitarnos sus datos personales y consecuencias de no
        hacerlo.
      </strong>
    </p>
    <p>
      El suministro de datos personales requiere una edad mínima de 14 años, o
      en su caso, disponer de capacidad jurídica suficiente para contratar.
    </p>
    <p>
      Los datos personales solicitados son necesarios para gestionar sus
      solicitudes y/o prestarle los servicios que pueda contratar, por lo que,
      si no nos los facilita, no podremos atenderle correctamente ni prestarle
      el servicio que ha solicitado.
    </p>
    <h2 className={styles.section_title}>
      <strong>3. Destinatario de sus datos</strong>
    </h2>
    <p>
      Sus datos son confidenciales y no se cederán a terceros, salvo que exista
      obligación legal y, en los siguientes supuestos:
    </p>
    <p>
      - Sus datos pueden ser accesibles por cualquiera de las sociedades del EL
      PASO 2000 indicadas en el apartado 1., titulares de talleres y puntos de
      venta EL PASO 2000, con la finalidad de que pueda acudir a cualquiera de
      nuestros talleres o puntos de venta y que le puedan prestar,
      independientemente de la zona geográfica en que se encuentre, servicios
      personalizados pudiendo acceder a su historial de reparaciones.
    </p>
    <p>
      - Asimismo, la cesión se realiza para que en nombre del EL PASO 2000,
      pueda recibir la newsletter corporativa y todas las ofertas y descuentos
      que ofrezcamos a nuestros clientes y usuarios, independientemente del
      taller en que reparen o compren productos habitualmente.
    </p>
    <h2 className={styles.section_title}>
      <strong>4. Derechos en relación con sus datos personales</strong>
    </h2>
    <p>
      Cualquier persona puede retirar su consentimiento en cualquier momento,
      cuando el mismo se haya otorgado para el tratamiento de sus datos. En
      ningún caso, la retirada de este consentimiento condiciona la ejecución
      del contrato de suscripción o las relaciones generadas con anterioridad.
    </p>
    <p>Igualmente, puede ejercer los siguientes derechos:</p>
    <ul className={styles.list}>
      <li>
        Solicitar el acceso a sus datos personales o su rectificación cuando
        sean inexactos.
      </li>
      <li>
        Solicitar su supresión cuando, entre otros motivos, los datos ya no sean
        necesarios para los fines para los que fueron recogidos.
      </li>
      <li>
        Solicitar la limitación de su tratamiento en determinadas
        circunstancias.
      </li>
      <li>
        Solicitar la oposición al tratamiento de sus datos por motivos
        relacionados con su situación particular.
      </li>
      <li>
        Solicitar la portabilidad de los datos en los casos previstos en la
        normativa.
      </li>
      <li>Otros derechos reconocidos en las normativas aplicables.</li>
    </ul>
    <p>
      Dónde y cómo solicitar sus Derechos: Mediante un escrito dirigido al
      responsable a su dirección postal o electrónica (indicadas en el apartado
      A), indicando la referencia “Datos Personales”, especificando el derecho
      que se quiere ejercer y respecto a qué datos personales.
    </p>
    <p>
      En caso de divergencias con la empresa en relación con el tratamiento de
      sus datos, puede presentar una reclamación ante la Autoridad de Protección
      de Datos (www.agpd.es).
    </p>
    <h2 className={styles.section_title}>
      <strong>5. Cookies</strong>
    </h2>
    <p>
      Esta página web, únicamente utiliza cookies técnicas, de personalización y
      análisis, propias y de terceros (Google Analytics), que en ningún caso
      tratan datos de carácter personal ni captan hábitos de navegación para
      fines publicitarios.
    </p>
    <p>
      Por ello, al acceder a nuestra web, en cumplimiento del artículo 22 de la
      Ley de Servicios de la Sociedad de la Información, al tratar cookies de
      análisis, le hemos solicitado su consentimiento para su uso y facilitado
      información de las mismas, que en todo caso se instalarán pasado un plazo
      de tiempo prudencial para que el usuario tenga tiempo de decidir prestar
      su consentimiento o no.
    </p>
    <h2 className={styles.section_title}>
      <strong>6. Seguridad de sus datos personales</strong>
    </h2>
    <p>
      Con el objetivo de salvaguardar la seguridad de sus datos personales, le
      informamos que hemos adoptado todas las medidas de índole técnica y
      organizativa necesarias para garantizar la seguridad de los datos
      personales suministrados de su alteración, pérdida, y tratamientos o
      accesos no autorizados.
    </p>
    <h2 className={styles.section_title}>
      <strong>7. Actualización de sus datos</strong>
    </h2>
    <p>
      Es importante que para que podamos mantener sus datos personales
      actualizados, nos informe siempre que haya habido alguna modificación en
      ellos, en caso contrario, no respondemos de la veracidad de los mismos.
    </p>
    <p>
      No nos hacemos responsables de la política de privacidad respecto a los
      datos personales que pueda facilitar a terceros por medio de los enlaces
      disponibles en nuestra página web.
    </p>
    <p>
      La presente Política de Privacidad puede ser modificada para adaptarlas a
      los cambios que se produzca en nuestra web, así como modificaciones
      legislativas o jurisprudenciales sobre datos personales que vayan
      apareciendo, por lo que exige su lectura, cada vez que nos facilite sus
      datos a través de esta Web.
    </p>
  </div>
)

const PoliticaPrivacidadContents = ({ ...props }: Props) => (
  <PoliticaPrivacidadEs {...props} />
)

export default PoliticaPrivacidadContents
